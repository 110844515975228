/**
 * Document: main.scss
 * Copyright © 2024, Prashant Shrestha
 * Date: 2024-07-14
 */

@charset "utf-8";

@import "./_includes/reset", "./_includes/responsive", "./_includes/mixins",
    "./_includes/fonts", "./_includes/colors";

$width_1200: 1200px;
$width_992: 992px;
$width_768: 768px;
$width_767: 767px;
$max_width: $width_992;

$header_gap: 70px;

body {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-family: $font_roboto;

    .main-container {
        margin: 0 20px;
        @include breakpoint(lg) {
            width: $max_width;
        }
        @include breakpoint(sm) {
            width: $width_768;
        }
        @include breakpoint(lg) {
            width: $max_width;
        }

        .inner-navigation {
            @include Flex(column);
            justify-content: center;
            margin-top: $header_gap;
            align-items: center;

            @include breakpoint(xs) {
                max-width: $width_767;
                flex-wrap: wrap;
            }

            &-part {
                @include Flex(row);
                align-items: center;
                justify-content: space-between;

                @include breakpoint(xs) {
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                }

                img {
                    margin: 0;
                    max-width: 65px;
                }

                .logo {
                    opacity: 0.8;
                    transition: opacity ease-in-out 0.4s;
                    margin-bottom: 40px;

                    &:hover {
                        opacity: 0.4;
                    }
                }

                .menu {
                    @include Flex(row);
                    align-items: center;
                    text-decoration: none;
                    padding: 11px 13px;
                    color: black;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    transition: background 0.6s ease-in-out;
                    margin: 0 7px;
                    border-radius: 3px;
                    font-size: 14px;

                    @include breakpoint(xs) {
                        margin-top: 30px;
                    }

                    svg {
                        margin: 0 7px 0 0;
                    }

                    &:hover {
                        background-color: black;
                        color: white;
                        border-radius: 3px;
                    }
                }

                .cart {
                    margin-left: 10px;

                    @include breakpoint(xs) {
                        margin-top: 30px;
                    }
                }

                &:nth-child(3) {
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                }

                .nav-cart {
                    position: relative;

                    &-count {
                        position: absolute;
                        margin: -9px 0 0 -15px;
                        background: black;
                        padding: 2px 7px;
                        border-radius: 9px;
                        color: white;
                        font-size: 14px;
                    }
                }
            }
        }

        &-inner {
            width: 100%;
            margin-top: $header_gap;

            &-mainpage-section {
                @include Flex(column);
                margin-top: $header_gap;

                h1 {
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    // font-weight: bold;
                    padding: 10px 0;
                    font-size: 17px;
                    @include Flex(row);
                    align-items: center;

                    svg {
                        margin-right: 5px;
                    }
                }

                &-cards {
                    width: 100%;
                    @include Flex(row);
                    justify-content: space-between;
                    margin: 20px 0;
                    flex-wrap: wrap;

                    &-card {
                        flex: 1;
                        // padding: 5px;
                        min-height: 300px;
                        // margin: 10px;
                        @include Flex(column);
                        justify-content: space-around;

                        &-mini {
                            flex: 1;

                            &:nth-child(1) {
                                margin-bottom: 10px;
                                // border: 1px solid red;
                            }
                        }

                        // &:nth-child(1) {
                        //     margin-right: 8px;
                        // }
                        // &:nth-child(2) {
                        //     margin-left: 8px;
                        // }
                    }

                    .apparels {
                        margin: 0 10px 0 0;
                        background-color: $color_breeze;
                        background-color: rgb(0, 0, 0);
                        height: 310px;
                        // background-position: center;
                        // background-blend-mode: hard-light;
                        // opacity: 0.4;
                        @include Flex(column);
                        justify-content: flex-end;
                        text-align: right;
                        cursor: pointer;
                        transition: all ease-in-out 0.3s;
                        flex: 1 1 310px;

                        @include breakpoint(xs) {
                            margin: 0 0 10px 0;
                        }
                        
                        @media only screen and (min-width: 660px) {
                            margin: 0 10px 0 0;
                        }

                        &-figure {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            transition: all ease-in-out 0.3s;

                            img {
                                width: 100%;
                                position: relative;
                                height: 100%;
                                overflow: hidden;
                                z-index: 1;
                                // opacity: 0.5;
                                // filter: brightness(100%);
                                transition: all ease-in-out 0.3s;
                            }

                            figcaption {
                                position: absolute;
                                z-index: 5;
                                /* top: 50%; */
                                /* right: 50%; */
                                width: 100%;
                                text-align: center;
                                top: 50%;
                                transition: all ease-in-out 0.3s;

                                span {
                                    border: 2px solid #fff;
                                    width: auto;
                                    padding: 7px 15px;
                                    color: white;
                                    text-transform: uppercase;
                                    letter-spacing: 0.6px;
                                    transition: all ease-in-out 0.3s;
                                    background-color: rgba(0, 0, 0, 0.523);
                                }
                            }
                        }

                        &:hover {
                            .apparels-figure {
                                img {
                                    // filter: brightness(250%);
                                }

                                figcaption {
                                    span {
                                        background-color: rgba(0, 0, 0, 1);
                                        @include box-shadow(
                                            0px 0px 293px 20px rgba(0, 0, 0, 1)
                                        );
                                    }
                                }
                            }
                        }
                    }

                    .accessories {
                        // margin: 0 0 0 10px;
                        background-color: $color_snowflake;
                        flex: 1 1 310px;

                        .accessories-section {
                            flex: 1;
                            height: 150px;

                            &:hover {
                                cursor: pointer;

                                .accessories-figure {
                                    opacity: 0.5;
                                }
                            }
                        }

                        &-figure {
                            width: 100%;
                            height: 150px;
                            position: relative;
                            overflow: hidden;
                            transition: all ease-in-out 0.3s;

                            img {
                                width: 100%;
                                position: absolute;
                                margin-top: -30%;
                                z-index: 1;
                                opacity: 0.6;
                                filter: brightness(100%);
                                transition: all ease-in-out 0.3s;
                            }

                            figcaption {
                                position: absolute;
                                z-index: 5;
                                /* top: 50%; */
                                /* right: 50%; */
                                width: 100%;
                                text-align: center;
                                top: 50%;
                                transition: all ease-in-out 0.3s;

                                span {
                                    border: 2px solid #fff;
                                    width: auto;
                                    padding: 7px 15px;
                                    color: white;
                                    text-transform: uppercase;
                                    letter-spacing: 0.6px;
                                    transition: all ease-in-out 0.3s;
                                    background-color: rgba(0, 0, 0, 0.523);
                                }
                            }
                        }

                        &:hover {
                            .apparels-figure {
                                img {
                                    filter: brightness(250%);
                                }

                                figcaption {
                                    span {
                                        background-color: rgba(0, 0, 0, 0.153);
                                        @include box-shadow(
                                            0px 0px 293px 17px rgba(0, 0, 0, 1)
                                        );
                                    }
                                }
                            }
                        }
                    }
                }

                .products {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                    grid-gap: 10px;

                    &-product {
                        flex-grow: 1;
                        flex-basis: 20%;
                        padding: 1%;
                        padding-bottom: 10px;
                        margin: 1%;
                        background-color: $color_french;
                        // border: 3px solid white;
                        @include Flex(column);
                        align-items: flex-start;
                        justify-content: flex-start;
                        overflow: hidden;
                        white-space: nowrap;
                        transition: box-shadow ease-in-out 0.3s;

                        &:hover {
                            cursor: pointer;
                            @include box-shadow(
                                0px 0px 13px 0px rgba(0, 0, 0, 0.128)
                            );
                        }

                        @include breakpoint(lg) {
                            flex-basis: 20%;
                        }
                        @include breakpoint(xs) {
                            flex-basis: 33.33%;
                        }
                        @include breakpoint(md) {
                            flex-basis: 25%;
                        }
                        @include breakpoint(sm) {
                            flex-basis: 20%;
                        }

                        &-image {
                            @include Flex(column);
                            align-items: center;
                            align-content: center;
                            justify-content: center;
                            flex: 1;
                            overflow: hidden;

                            img {
                                max-width: 100%;
                            }
                        }

                        &-info {
                            width: 90%;
                            overflow: hidden;
                            margin: 0 10px;

                            &-title {
                                width: 100%;
                                font-size: 15px;
                                // font-weight: bold;
                                // text-align: center;
                                line-height: 17px;
                                overflow: hidden;
                                text-wrap: nowrap;
                                text-overflow: ellipsis;
                                display: inline-block;
                                margin-bottom: 5px;
                            }

                            &-price {
                                width: 100%;
                                font-size: 15px;
                                margin: 10px 0;
                                // text-align: center;
                                color: $color_black_mana;
                            }
                        }

                        &:last-child {
                            flex-grow: 0;
                            flex-shrink: 0;
                            flex-basis: 20%;

                            @include breakpoint(lg) {
                                flex-basis: 20%;
                            }
                            @include breakpoint(xs) {
                                flex-basis: 33.33%;
                            }
                            @include breakpoint(md) {
                                flex-basis: 25%;
                            }
                            @include breakpoint(sm) {
                                flex-basis: 20%;
                            }
                        }
                    }
                }
            }
        }

        .footer {
            margin-bottom: 30px;

            .external-links {
                @include Flex(column);
                justify-content: flex-start;
                min-height: auto;
                list-style: square;
                padding: 0 0 0 20px;

                li {
                    padding: 3px 0;
                }
            }

            h3 {
                font-size: 11px;
                text-align: center;
                margin: 30px 0;
                line-height: 20px;
                letter-spacing: 0.2px;
            }
        }
    }
}
